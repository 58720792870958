import { Form } from "@quillforms/renderer-core";
import type { FormBlocks, FormMessages } from "@quillforms/types";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";

import "./styles.css";
import questions from "./questions";
import preguntas from "./preguntas";
import { messages } from "./messages";
import { mensajes } from "./mensajes";
import React from "react";


// Translation:
const questionObj = () => {

  var language = window.navigator.languages[0] || window.navigator.language;
  if (language.startsWith("es")) {
    return preguntas as FormBlocks;
  } else {
    return questions as FormBlocks;
  }
}

const messagesObj = () => {

  var language = window.navigator.languages[0] || window.navigator.language;
  if (language.startsWith("es")) {
    return mensajes as FormMessages;
  } else {
    return messages as FormMessages;
  }
}

 
registerCoreBlocks();
const App = () => {
  const apiKeyAirtable = process.env.REACT_APP_API_KEY_AIRTABLE;
  const baseId = 'app8afdeP1SxSAkKO';
  const table = 'tbllGLdXSgfbTlj4y';

  const handleSubmit = async (formData, { completeForm, setIsSubmitting }) => { 
    setIsSubmitting(true);
  
  const goToCalendly = () => {
    const delay = 2000;
    setTimeout(() => {
      window.location.href = "https://calendly.com/katherinelopezvirto/45min";
    }, delay);
  }

    try { 
      // We save the value of each field in a const
      const metadataPersonNameValue = formData.answers["metadataPersonName"].value;
      const rolValue = formData.answers["rol"].value;
      const contactEmailValue = formData.answers["contactEmail"].value;
      const metadataNameOrganizationValue = formData.answers["metadataNameOrganization"].value;
      const metadataDescriptionValue = formData.answers["metadataDescription"].value;
      const metadataIndustryValue = formData.answers["metadataIndustry"].value;

      // Use this consts to build the object that we will send to airtable
      const datosFormulario = {
        fields: {
          "metadataPersonName": metadataPersonNameValue,
          "Rol": rolValue,
          "contactEmail": contactEmailValue,
          "metadataNameOrganization": metadataNameOrganizationValue,
          "metadataDescription": metadataDescriptionValue,
          "metadataIndustry": metadataIndustryValue,
        }
      };

      fetch(`https://api.airtable.com/v0/${baseId}/${table}`, {
          method: 'POST',
          headers: {
          'Authorization': `Bearer ${apiKeyAirtable}`, 
          'Content-Type': 'application/json'
       },
        body: JSON.stringify(datosFormulario)
        })
        .then(response => response.json())
        .then(result => console.log(result))
        .catch(error => console.error(error));
            fbq('track', 'Lead', { datosFormulario });
            dataLayer.push({
              'event': 'registrationComplete'
             });            
            console.log('Datos enviados exitosamente a Airtable');
            completeForm();
            setIsSubmitting(false);
        } catch (error) {
          console.error("Error al enviar datos a Airtable:", error);
          setIsSubmitting(false);
        }
        goToCalendly();

  };

  return (
    <div style={{ backgroundColor: "#ded0f0", width: "100%", height: "100vh" }}>
      <Form
        formObj={{
          blocks: questionObj(),
          messages: messagesObj(),
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            disableNavigationArrows: false,
            disableProgressBar: false,
          },
          theme: {
            font: "Outfit",
            backgroundColor: "#ded0f0",
            buttonsBgColor: "#2a4940",
            logo: {
              src: "/kreivo-logo.svg",
            },
            questionsColor: "#000",
            answersColor: "#9063cd",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 25,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#000",
            progressBarBgColor: "#ccc",
          },
        }}
        beforeGoingNext={({
          currentBlockId,
          answers,
          goNext
        }) => {
          window.amplitude.track(`form:${currentBlockId}`, {answers,
            ...Array.from(new URLSearchParams(new URL(window.location.href).search).entries()).reduce((p, [k, v]) =>({ [k]:v , ...p}), {})})
          goNext();
        }}


        onSubmit={handleSubmit} applyLogic={false} />
    </div>
  );
};

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export default App;
