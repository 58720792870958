export const messages =
 {
    'label.button.ok': "Send",
    'label.hintText.enter': "Press Enter ↵ to continue",
	'block.dropdown.placeholder': "Select an option",
	'block.shortText.placeholder': "Type your answer here",
	'block.longText.placeholder': "Type your answer here",
	'block.longText.hint': "<strong>Shift ⇧ + Enter ↵</strong> to make a line break",
	'block.number.placeholder': "Type your answer here",
	'block.email.placeholder': "Type your email here",
	'block.defaultThankYouScreen.label': "Redirecting to Calendly.",
	'label.progress.percent': "{{progress:percent}}% completed",
	'label.errorAlert.required': "This field is required!",
	'label.errorAlert.number': "Numbers only!",
	'label.errorAlert.email': "Invalid email!",
	'label.errorAlert.url': "Invalid url!",
	'label.errorAlert.maxCharacters': "Maximum characters reached!",
	'label.errorAlert.maxNum': "Please enter a number lower than {{attribute:max}}", // see if attribute max shows what I want
	'label.errorAlert.selectionRequired': "Choose an option",
	'block.dropdown.noSuggestions': "Choose an option",
	'label.submitBtn': "Submit",
 }
