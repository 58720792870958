export default [
    {
      name: "welcome-screen",
      id: "jg1401r",
      attributes: {
        label: "¡Hola! 👋",
        description:
          "¡Gracias por tu interés en la iniciativa que Virto ha venido trabajando para tu organización y para ti; con el propósito de mejorar la gobernanza, transparencia y proceso de toma de decisiones. \n\nCon el objetivo de entender mejor tus necesidades, desafíos y motivaciones de tu organización nos gustaría invitarte a completar este breve formulario. 👇\n\nLa información que sea suministrada nos ayudará a personalizar el espacio en el que te escucharemos y podremos definir siguientes pasos. 🚀 \n\n¡Desde Virto esperamos poder colaborar contigo para beneficio de tu organización! 📈",
          buttonText: "Comenzar",
        attachment: {
          type: "image",
          url: "/kreivo-logo.svg",
        },
        attachmentMaxWidth: "300px",
      },
    },
    {
      id: "person",
      name: "group",
      attributes: {
        label: "¡Algunas preguntas sobre ti!",
        attachment: {
          type: "image",
          url: "/img/group.svg",
        },
        layout: "split-right",
      },
      innerBlocks: [
        {
          id: "metadataPersonName",
          name: "short-text",
          attributes: {
            label: "¿Cuál es tu nombre?",
            required: true,
          }
        },
        {
          id: "rol",
          name: "dropdown",
          attributes: {
            label: "¿Cuál es tu rol dentro de la organización?",
            required: true,
            choices: [
              {
                label: "Director Ejecutivo (CEO)",
                value: "Director Ejecutivo (CEO)",
              },
              {
                label: "Director Financiero (CFO)",
                value: "Director Financiero (CFO)",
              },
              {
                label: "Gerente de Marketing",
                value: "Gerente de Marketing",
              },
              {
                label: "Director de Tecnología de la información (CTO)",
                value: "Director de Tecnología de la información (CTO)",
              },
              {
                label: "Gerente de Desarrollo de Negocio",
                value: "Gerente de Desarrollo de Negocio",
              },
              {
                label: "Gerente de Producto",
                value: "Gerente de Producto",
              },
              {
                label: "Gerente de Proyecto",
                value: "Gerente de Proyecto",
              },
              {
                label: "Otro",
                value: "Otro",
              },
            ],
          }
        }
      ]
    },
    {
      name: "email",
      id: "contactEmail",
      attributes: {
        required: true,
        label: "Nos gustaría mantener el contacto contigo y tu organización, ingresa un correo electrónico.",
      },
    },
    {
      id: "organization",
      name: "group",
      attributes: {
        label: "Ahora: ¡Algunas preguntas sobre tu Organización!",
        attachment: {
          type: "image",
          url: "/img/group.svg",
        },
        layout: "split-left",
      },
      innerBlocks: [
        {
          id: "metadataNameOrganization",
          name: "short-text",
          attributes: {
            label: "¿Cuál es el nombre de tu organización?",
            required: true,
          }
        },
        {
          name: "long-text",
          id: "metadataDescription",
          attributes: {
            attachment: {
              type: "image",
              url: "/img/group.svg",
              setMaxCharacters: true,
              maxCharacters: 300,
            },
            layout: "split-left",
            required: true,
            label: "Agrega una breve descripción de tu organización. Intenta responder: ¿Qué hace tu organización? ¿Cuál es la misión?",
          },
        },
     
      ]
    },
    {
      name: "dropdown",
      id: "metadataIndustry",
      attributes: {
        label:
        "Por último: ¿Dentro de qué industria se encuentra tu organización?",
        required: true,
        choices: [
          {
            label: "Tecnología",
            value: "Tecnología",
          },
          {
            label: "Salud",
            value: "Salud",
          },
          {
            label: "Educación",
            value: "Educación",
          },
          {
            label: "Finanzas",
            value: "Finanzas",
          },
          {
            label: "ONG",
            value: "ONG",
          },
          {
            label: "Otro",
            value: "Otro",
          },
        ],
      },
    },
    {
        name: "statement",
        id: "thank-you",
        attributes: {
          label: "¡Gracias por tomarte el tiempo de completar este formulario y compartir información valiosa sobre tu organización! \n\nEstamos emocionados de aprender más sobre tus necesidades y desafíos, y explorar cómo una solución en DAO podría beneficiar a tu organización.\n\n<strong>Ahora te invitamos a agendar una llamada con el equipo Virto.</strong> Durante está llamada, tendremos la oportunidad de profundizar en tus comentarios, objetivos, desafíos, y explorar cómo podemos colaborar para encontrar soluciones efectivas.\n\n¡Estamos emocionados por tener esa conversación!\n\n¡Gracias!",
          buttonText: "Enviar información",
          attachment: {
            type: "image",
            url: "/img/group.svg",
          },
          layout: "split-left",
        },
      },
  ];