export default [
  {
    name: "welcome-screen",
    id: "jg1401r",
    attributes: {
      label: "Hello! 👋",
      description:
        "Thank you for your interest in the initiative that Virto has been working on for your organization and you; with the purpose of improving governance, transparency, and decision-making processes.\n\nTo better understand your organization's needs, challenges, and motivations, we would like to invite you to complete this brief form. 👇\n\nThe information provided will help us personalize the space where we will listen to you and define next steps. 🚀\n\nFrom Virto, we look forward to collaborating with you for the benefit of your organization! 📈",
        buttonText: "Get Started",
      attachment: {
        type: "image",
        url: "/kreivo-logo.svg",
      },
      attachmentMaxWidth: "300px",
    },
  },
  {
    id: "person",
    name: "group",
    attributes: {
      label: "¡Some questions about you!",
      attachment: {
        type: "image",
        url: "/img/group.svg",
      },
      layout: "split-right",
    },
    innerBlocks: [
      {
        name: "short-text",
        id: "metadataPersonName",
        attributes: {
          layout: "split-right",
          required: true,
          label: "What is your name?",
        },
      }, 
      {
        name: "dropdown",
        id: "rol",
        attributes: {
          label:
          "What is your role within the organization?",
          required: true,
          choices: [
            {
              label: "Chief Executive Officer (CEO)",
              value: "Chief Executive Officer (CEO)",
            },
            {
              label: "Chief Financial Officer (CFO)",
              value: "Chief Financial Officer (CFO)",
            },
            {
              label: "Marketing Manager",
              value: "Marketing Manager",
            },
            {
              label: "Chief Technology Officer (CTO)",
              value: "Chief Technology Officer (CTO)",
            },
            {
              label: "Business Development Manager",
              value: "Business Development Manager",
            },
            {
              label: "Product Manager",
              value: "Product Manager",
            },
            {
              label: "Project Manager",
              value: "Project Manager",
            },
            {
              label: "Other",
              value: "Other",
            },
          ],
        },
      },
    ]
  },
  {
    name: "email",
    id: "contactEmail",
    attributes: {
      required: true,
      label: "We would like to stay in touch with you and your organization, please enter an email address.",
    },
  },
  {
    name: "group",
    id: "organization",
    attributes: {
      label: "Now: Some questions about your Organization!",
      attachment: {
        type: "image",
        url: "/img/group.svg",
      },
      layout: "split-left",
    },
    innerBlocks: [
      {
        name: "short-text",
        id: "metadataNameOrganization",
        attributes: {
          required: true,
          label: "What is the name of your organization?",
        },
      },
      {
        name: "long-text",
        id: "metadataDescription",
        attributes: {
          attachment: {
            setMaxCharacters: true,
            maxCharacters: 300,
          },
          required: true,
          label: "Add a brief description of your organization. Try to answer: What does your organization do? What is the mission?",
        },
      },
   
    ]
  },
  {
    name: "dropdown",
    id: "metadataIndustry",
    attributes: {
      label:
      "Which industry does your organization belong to?",
      required: true,
      choices: [
        {
          label: "Technology",
          value: "Technology",
        },
        {
          label: "Health",
          value: "Health",
        },
        {
          label: "Education",
          value: "Education",
        },
        {
          label: "Finance",
          value: "Finance",
        },
        {
          label: "NGO",
          value: "NGO",
        },
        {
          label: "Other",
          value: "Other",
        },
      ],
    },
  },
  {
    name: "statement",
    id: "thank-you",
    attributes: {
      label:"Thank you for taking the time to complete this form and share valuable information about your organization!\n\nWe're excited to learn more about your needs and challenges, and explore how a DAO solution could benefit your organization.\n\n<strong>Now we invite you to schedule a call with the Virto team.</strong> During this call, we'll have the opportunity to delve into your feedback, goals, challenges, and explore how we can collaborate to find effective solutions.\n\nWe're looking forward to having that conversation!\n\nThank you!",
      buttonText: "Send information",
      attachment: {
        type: "image",
        url: "/img/group.svg",
      },
      layout: "split-left",
    },
  },
];
